const initialState = {
  userSignUpDetails: null,
};

const userSignUpDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_USER_SIGN_UP_DETAILS": {
      return {
        ...state,
        userSignUpDetails: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default userSignUpDetailsReducer;
