import nextIcon from "../assets/images/next.svg";
import { NextArrow, PrevArrow } from "../components/Ui/Arrow";

export const tripSettings = (journyDataLength) => {
    // Default settings
  const defaultSettings = {
    lazyLoad: true,
    dots: false,
    arrows: true,
    speed: 500,
    slidesToScroll: 1,
    arrow: true,
    nextArrow: <NextArrow img={nextIcon} alt="Arrow icon" />,
    prevArrow: <PrevArrow img={nextIcon} alt="Arrow icon" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          initialSlide: 2,
          dots: false,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  // Adjust settings based on journyDataLength
  if (journyDataLength < 4) {
    defaultSettings.slidesToShow = journyDataLength;
    defaultSettings.infinite= false;
    defaultSettings.centerMode = true;
    // defaultSettings.centerPadding = "0";
  } else {
    defaultSettings.infinite= true;
    defaultSettings.slidesToShow = 4; // Reset to default value when journyDataLength is not less than 4
    defaultSettings.centerMode = false;
    defaultSettings.centerPadding = "50px"; // Adjust this padding as needed
  }

  return defaultSettings;
};