import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import Spinner from "../../bootstrap/Spinner";
import emailPhoto from "../../assets/login/email.svg";
import Layout from "../../bootstrap/Layout";
import TextField from "../../bootstrap/TextField/index.jsx";
import Input from "../../components/Ui/Input/index.jsx";
import languageData from "../../lib/lang.config.json";
import { setUserSignUpDetails } from "../../redux/action/userSignUpAction";
import IconArrowBack from "../../assets/images/icon-arrow-back.svg";
import {
  Account,
  CheckBox,
  ConditionLink,
  Container,
  CountrySelect,
  ErrrorMsg,
  Extra,
  FirstNameAndNid,
  Form,
  FormHeader,
  FormLogo,
  FormWrapper,
  InnerFormWrapper,
  InputField,
  LogoArea,
  NameWrapper,
  PasswordWrapper,
  PhoneWrapper,
  Select,
  SignupButton,
  SingupLink,
  SubHeader,
  TypeSelect,
  DOBInput,
  Row,
  PasswordField,
  ErrorMsg,
  Months,
  CountryCodeSelect,
  ModalHeader,
  ModalHeaderButton,
  ModalHeaderTitle,
  SCCountryCodeList,
  SCCountryCodeItem,
  ScrollWrapper,
} from "./Signup.styles.js";
import { SpinnerWrapper } from "../Checkout/Checkout.styles";
import { getFlagEmoji } from "../../helpers";
import Modal from "../../components/Ui/Modal";

// Mr.,Mrs,Miss,Mdm,Ms,Mstr,Dr,Prof,Others
const titles = [
  "Mr.",
  "Mrs",
  "Miss",
  "Mdm",
  "Ms",
  "Mstr",
  "Dr",
  "Prof",
  "Others",
];

const months = [
  {
    title: "january",
    value: 1,
  },
  {
    title: "february",
    value: 2,
  },
  {
    title: "march",
    value: 3,
  },
  {
    title: "april",
    value: 4,
  },
  {
    title: "may",
    value: 5,
  },
  {
    title: "june",
    value: 6,
  },
  {
    title: "july",
    value: 7,
  },
  {
    title: "august",
    value: 8,
  },
  {
    title: "september",
    value: 9,
  },
  {
    title: "october",
    value: 10,
  },
  {
    title: "november",
    value: 11,
  },
  {
    title: "december",
    value: 12,
  },
];

export const Signup = () => {
  const dispatch = useDispatch();
  const { webSettingData } = useSelector((state) => state.busLists);
  const [openModel, setOpenModel] = useState(false);


  const [values, setValues] = useState({
    title: "",
    firstname: "",
    no_firstname: false,
    lastname: "",
    date: "",
    month: "",
    year: "",
    email: "",
    confirm_email: "",
    country_code: "65",
    // area: "",
    phone: "",
    country: "",
    state: "",
    password: "",
    confirm_password: "",
    terms_condition: false,
  });
  const [errors, setErrors] = useState({
    title: "",
    firstname: "",
    no_firstname: false,
    lastname: "",
    date: "",
    month: "",
    year: "",
    email: "",
    confirm_email: "",
    country_code: "",
    // area: "",
    phone: "",
    country: "",
    state: "",
    password: "",
    confirm_password: "",
    terms_condition: false,
  });
  const [userProfileInfo, setUserProfileInfo] = useState(null);
  const [passworType, setPasswordType] = useState(true);
  const [rePassworType, setRePasswordType] = useState(true);
  const [countryName, setCountryName] = useState([]);
  const [phoneStatus, setPhoneStatus] = useState(null);
  const [emailStatus, setEmailStatus] = useState(null);
  const [idNumberStatus, setIdNumberStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const exceptThisSymbols = ["e", "E", "+", "-", "."];
  const history = useHistory();
  const [isChecked, setIsChecked] = useState(false);
  const [days, setDays] = useState([]);
  const [years, setYears] = useState([]);
  const [states, setStates] = useState([]);
  const [filteredCountryList, setFilteredCountryList] = useState(countryName);
  const [country, setCountry] = useState({
    flag: "https://flagcdn.com/sg.svg",
    countryCode: "+65",
  });


  let countries = [];
  countryName.map((item) =>
    countries.push({
      value: item.nicename,
      label: item.nicename,
      id: item.id,
    })
  );

  useEffect(() => {
    setUserProfileInfo(JSON.parse(localStorage.getItem("userProfileInfo")));

    fetch(`${process.env.REACT_APP_API_MODULE_DOMAIN}/countries`)
      .then((res) => res.json())
      .then((data) => {setCountryName(data.data);setFilteredCountryList(data.data);});    
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      history.push("/");
    }
  }, []);

  useEffect(() => {
    const days = [],
      years = [];
    for (let i = 1; i <= 31; i++) {
      days.push(i);
    }
    for (let i = new Date().getFullYear(); i > 1900; i--) {
      years.push(i);
    }
    setDays(days);
    setYears(years);
  }, []);

  const openModelHandeler = () => {
      setOpenModel(true);
  }
  const onClose = () => {
    setOpenModel(false);
  setFilteredCountryList(countryName);

  };

const handleCountrySelect = (country) => {
  setValues({ ...values, country_code: country.phonecode });
  setCountry((prev) => ({
    ...prev,
    flag: `https://flagcdn.com/${(country?.iso).toLowerCase()}.svg`,
    // name: country?.name?.common,
    countryCode: country?.phonecode.startsWith("+")
    ? country?.phonecode
    : `+${country?.phonecode}`,
  }));
  onClose();
};

const filterCountryList = (event) => {
  const value = event.target.value;

  const filtered = countryName.filter((country) => {
    const countryCode = `${country?.phonecode}`;

    return (
      country?.name?.toLowerCase().includes(value.toLowerCase()) ||
      countryCode.includes(value)
    );
  });

  setFilteredCountryList(filtered);
};
  const getStates = async (id) => {
    fetch(`${process.env.REACT_APP_API_MODULE_DOMAIN}/states/${id}`)
      .then((res) => res.json())
      .then((data) => setStates(data.data));
  };

  const emailRegEx = /[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$/;
    const phoneRegEx = /^[6-9]\d{7,9}$/;

  const validateInput = (name, value) => {
    switch (name) {
      case "title":
        if (!value) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            title: "Title is required.",
          }));
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            title: "",
          }));
        }
        break;

      case "firstname":
        if (!value && !values?.no_firstname) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            firstname: "First name is required.",
          }));
        } else if (!/^[a-zA-Z\s]+$/.test(value)) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            firstname: "First Name must contain only letters and spaces.",
          }));
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            firstname: "",
          }));
        }
        break;

      case "lastname":
        if (!value) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            lastname: "Last name is required.",
          }));
        } else if (!/^[a-zA-Z\s]+$/.test(value)) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            lastname: "Last Name must contain only letters and spaces.",
          }));
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            lastname: "",
          }));
        }
        break;

      case "date":
        if (!value) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            date: "Date is required.",
          }));
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            date: "",
          }));
        }
        break;

      case "month":
        if (!value) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            month: "Month is required.",
          }));
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            month: "",
          }));
        }
        break;

      case "year":
        if (!value) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            year: "Year is required.",
          }));
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            year: "",
          }));
        }
        break;

      case "email":
        if (!value) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            email: "Email is required.",
          }));
        } else if (!emailRegEx.test(value)) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            email: "Invalid email.",
          }));
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            email: "",
          }));
        }
        break;

      case "confirm_email":
        if (!value) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            confirm_email: "Confirm email is required.",
          }));
        } else if (!emailRegEx.test(value)) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            confirm_email: "Invalid email.",
          }));
        } else if (value !== values?.email) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            confirm_email: "Email doesn't match.",
          }));
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            confirm_email: "",
          }));
        }
        break;

      case "country_code":
        if (!value) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            country_code: "Country code is required.",
          }));
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            country_code: "",
          }));
        }
        break;

      // case "area":
      //   if (!value) {
      //     setErrors((prevErrors) => ({
      //       ...prevErrors,
      //       area: "Area is required.",
      //     }));
      //   } 
      //   else if (!/^[a-zA-Z0-9]*$/.test(value)) {
      //     setErrors((prevErrors) => ({
      //       ...prevErrors,
      //       area: "Invalid area name",
      //     }));
      //   } else {
      //     setErrors((prevErrors) => ({
      //       ...prevErrors,
      //       area: "",
      //     }));
      //   }
      //   break;

      case "phone":
        if (!value) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            phone: "Phone is required.",
          }));
        } else if (!phoneRegEx.test(value)) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            phone: "Invalid phone.",
          }));
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            phone: "",
          }));
        }
        break;

      case "country":
        if (!value) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            country: "Country is required.",
          }));
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            country: "",
          }));
        }
        break;

      case "state":
        if (!value) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            state: "State is required.",
          }));
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            state: "",
          }));
        }
        break;

      case "password":
        if (!value) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            password: "Password is required.",
          }));
        } else if (values?.length < 6) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            password: "Password should have at least 6 characters.",
          }));
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            password: "",
          }));
        }
        break;

      case "confirm_password":
        if (!value) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            confirm_password: "Confirm password is required.",
          }));
        } else if (value !== values?.password) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            confirm_password: "Password doesn't match.",
          }));
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            confirm_password: "",
          }));
        }
        break;
    }
  };

  const validateForm = () => {
    const errors = {};

    if (!values.title) {
      errors.title = "Title is required.";
    }

    if (!values.firstname && !values?.no_firstname) {
      errors.firstname = "First name is required.";
    }

    if (!values.lastname) {
      errors.lastname = "Last name is required.";
    }

    if (!values.date) {
      errors.date = "Date is required.";
    }

    if (!values.month) {
      errors.month = "Month is required.";
    }

    if (!values.year) {
      errors.year = "Year is required.";
    }

    if (!values.email) {
      errors.email = "Email is required.";
    } else if (!emailRegEx.test(values.email)) {
      errors.email = "Invalid email.";
    }

    if (!values.confirm_email) {
      errors.confirm_email = "Confirm email is required.";
    } else if (!emailRegEx.test(values.email)) {
      errors.confirm_email = "Invalid email.";
    } else if (values?.email !== values?.confirm_email) {
      errors.confirm_email = "Email doesn't match.";
    }

    if (!values.country_code) {
      errors.country_code = "Country code is required.";
    }

    // if (!values.area) {
    //   errors.area = "Area is required.";
    // }
    // if (!/^[a-zA-Z0-9]*$/.test(values.area)) {
    //   errors.area = "Invalid area name.";
    // }

    if (!values.phone) {
      errors.phone = "Phone is required.";
    }else if (!phoneRegEx.test(values.phone)) {
      errors.phone = "Invalid phone.";
    }

    if (!values.country) {
      errors.country = "Country is required.";
    }

    if (!values.state) {
      errors.state = "State is required.";
    }

    if (!values.password) {
      errors.password = "Password is required.";
    } else if (values.password?.length < 6) {
      errors.password = "Password should have at least 6 characters.";
    }

    if (!values.confirm_password) {
      errors.confirm_password = "Confirm password is required.";
    } else if (values?.password !== values?.confirm_password) {
      errors.confirm_password = "Password doesn't match.";
    }

    if (!values.terms_condition) {
      errors.terms_condition = "Select Terms and Conditions.";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  // const isFormValid = () => {
  //   return Object.keys(errors).length === 0;
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
    validateInput(name, value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const formData = new FormData();
      formData.append("country_code", values?.country_code);
      values?.phone && formData.append("phone", values?.phone);
      values?.firstname && formData.append("first_name", values?.firstname);
      values?.email && formData.append("email", values?.email);

      try {
        setIsLoading(true);
        const response = await fetch(
          `${process.env.REACT_APP_API_MODULE_DOMAIN}/passangers/generate/otp`,
          {
            method: "POST",
            body: formData,
          }
        );

        const result = await response.json();
        setIsLoading(false);

        if (result?.status === "success") {
          dispatch(setUserSignUpDetails(values));
          toast.success(result?.message);
          history.push("/otp-verification");
          setValues({
            title: "",
            firstname: "",
            no_firstname: false,
            lastname: "",
            date: "",
            month: "",
            year: "",
            email: "",
            confirm_email: "",
            country_code: "",
            // area: "",
            phone: "",
            country: "",
            state: "",
            password: "",
            confirm_password: "",
            terms_condition: false,
          });
        } else {
          !toast.isActive("loginmail") &&
            toast.error(result?.error?.login_email, { toastId: "loginmail" });
          !toast.isActive("loginmobile") &&
            toast.error(result?.error?.login_mobile, {
              toastId: "loginmobile",
            });
          !toast.isActive("pass") &&
            toast.error(result?.error?.password, { toastId: "pass" });
          !toast.isActive("rePass") &&
            toast.error(result?.error?.repassword, { toastId: "rePass" });
          // !toast.isActive("idNumber") &&
          //   toast.error(result?.error?.id_number, { toastId: "idNumber" });
          !toast.isActive("phone") &&
            toast.error(result?.error?.phone, { toastId: "phone" });
          !toast.isActive("message") &&
            toast.error(result?.message || "Something went wrong!", {
              toastId: "message",
            });
        }
      } catch (error) {
        setIsLoading(false);
        console.error("error", error);
      }
    }
  };

  const handlePhoneBlur = async () => {
    const formData = new FormData();

    formData.append("country_code", values?.country_code || null);

    formData.append("login_mobile", values?.phone);

    const response = await fetch(
      `${process.env.REACT_APP_API_MODULE_DOMAIN}/passangers/mobile`,
      {
        method: "POST",
        body: formData,
      }
    );

    const result = await response.json();
    setPhoneStatus(result?.status);
   if (result.status === "success") {
     setErrors((prevErrors) => ({
       ...prevErrors,
       phone: result.message,
     }));
   }
  };

  const handleEmailBlur = async () => {
    const formData = new FormData();

    formData.append("login_email", values?.email);

    const response = await fetch(
      `${process.env.REACT_APP_API_MODULE_DOMAIN}/passangers/email`,
      {
        method: "POST",
        body: formData,
      }
    );

    const result = await response.json();
    setEmailStatus(result?.status);
   if (result.status === "success") {
     setErrors((prevErrors) => ({
       ...prevErrors,
       email: result.message,
     }));
   }
  };

  const handlePasswordTypeChange = () => {
    setPasswordType((prevState) => !prevState);
  };

  const handleRePasswordTypeChange = () => {
    setRePasswordType((prevState) => !prevState);
  };
  const checkboxHandler = (values) => {
    if (values?.terms_condition) {
      setErrors(prev => { return { ...prev, terms_condition: "Select terms & conditions." } })
    } else {
      setErrors(prev => { return { ...prev, terms_condition: "" } })
    }
  }

  return (
    <Layout title="Signup" userProfileInfo={userProfileInfo}>
      <Container>
        {isLoading ? (
          <SpinnerWrapper>
            <Spinner />
          </SpinnerWrapper>
        ) : (
          <FormWrapper>
            <InnerFormWrapper>
              <LogoArea>
                <FormLogo src={webSettingData?.headerlogo} alt="logo" />
              </LogoArea>
              <Form onSubmit={handleSubmit}>
                <FormHeader>
                  {languageData?.sign_up_page_title[webSettingData?.language]}
                </FormHeader>
                <SubHeader>Personal Information</SubHeader>
                <InputField>
                  <label htmlFor="title">Title *</label>
                  <TypeSelect name="title" onChange={handleChange}>
                    <option value="">Select Title</option>
                    {titles.map((title) => {
                      return (
                        <option
                          key={title}
                          value={title}
                          selected={title === values?.title}
                        >
                          {title}
                        </option>
                      );
                    })}
                  </TypeSelect>
                  {errors?.title && <ErrorMsg>{errors?.title}</ErrorMsg>}
                </InputField>
                <InputField>
                  <label htmlFor="firstname">
                    First / Given name (as in passport) *
                  </label>
                  <TextField
                    name="firstname"
                    type="text"
                    placeholder={
                      languageData?.sign_up_page_input_name[
                        webSettingData?.language
                      ]
                    }
                    maxlength="30"
                    value={values?.firstname}
                    onChange={handleChange}
                    autoComplete="off"
                  />
                  {errors?.firstname && (
                    <ErrorMsg>{errors?.firstname}</ErrorMsg>
                  )}
                </InputField>

                <CheckBox>
                  <Input
                    type="checkbox"
                    name="no_firstname"
                    isChecked={values?.no_firstname}
                    onChange={(e) => {
                      setValues((prevObj) => ({
                        ...prevObj,
                        no_firstname: e.target.checked,
                      }));
                    }}
                  />
                  <Account htmlFor="no_firstname">
                    This passenger does not have a first/given name in the
                    passport.
                  </Account>
                </CheckBox>

                <InputField>
                  <label htmlFor="lastname">
                    Last / Family name (as in passport) *
                  </label>
                  <TextField
                    name="lastname"
                    type="text"
                    placeholder={
                      languageData?.sign_up_page_input_last_name[
                        webSettingData?.language
                      ]
                    }
                    maxlength="30"
                    onChange={handleChange}
                    value={values?.lastname}
                    autoComplete="off"
                  />
                  {errors?.lastname && <ErrorMsg>{errors?.lastname}</ErrorMsg>}
                </InputField>

                <InputField>
                  <label htmlFor="dob">Date of Birth*</label>
                  <DOBInput>
                    <Select name="date" onChange={handleChange}>
                      <option value="">DD</option>
                      {days.map((day, index) => {
                        return (
                          <option
                            key={index}
                            value={day}
                            selected={day === values?.date}
                          >
                            {day}
                          </option>
                        );
                      })}
                    </Select>
                    <Select name="month" onChange={handleChange}>
                      <option value="">MM</option>
                      {months.map((month, index) => {
                        return (
                          <Months
                            key={index}
                            value={month?.value}
                            selected={month?.value === values?.month}
                          >
                            {month?.title}
                          </Months>
                        );
                      })}
                    </Select>
                    <Select name="year" onChange={handleChange}>
                      <option value="">YYYY</option>
                      {years.map((year, index) => {
                        return (
                          <option
                            key={index}
                            value={year}
                            selected={year === values?.year}
                          >
                            {year}
                          </option>
                        );
                      })}
                    </Select>
                  </DOBInput>
                  {errors?.date && <ErrorMsg>{errors?.date}</ErrorMsg>}
                  {errors?.month && <ErrorMsg>{errors?.month}</ErrorMsg>}
                  {errors?.year && <ErrorMsg>{errors?.year}</ErrorMsg>}
                </InputField>

                <SubHeader>Contact Information</SubHeader>

                <InputField>
                  <label htmlFor="email">
                    Email address* (Must be unique and not shared with another
                    account)
                  </label>
                  <TextField
                    name="email"
                    type="email"
                    value={values?.email}
                    placeholder="Email Address"
                    onChange={handleChange}
                    onBlur={handleEmailBlur}
                    autoComplete="off"
                  />
                  {errors?.email && <ErrorMsg>{errors?.email}</ErrorMsg>}
                </InputField>

                <InputField>
                  <label htmlFor="confirm_email">Confirm email address*</label>
                  <TextField
                    name="confirm_email"
                    type="email"
                    value={values?.confirm_email}
                    placeholder="Confirm Email Address"
                    onChange={handleChange}
                    autoComplete="off"
                  />
                  {errors?.confirm_email && (
                    <ErrorMsg>{errors?.confirm_email}</ErrorMsg>
                  )}
                </InputField>

                <Modal
                  isOpen={openModel} onClose={onClose}>
                  <ModalHeader>
                      <ModalHeaderButton onClick={onClose}>
                        <img src={IconArrowBack} alt="" />
                      </ModalHeaderButton>
                      <ModalHeaderTitle>Select Country Code</ModalHeaderTitle>
                    </ModalHeader>
                    <TextField
                    name="contry_code"
                    type="text"
                    // value={values?.confirm_email}
                    placeholder="Search By Country or Country Code"
                    onChange={filterCountryList}
                    autoFocus={true}
                    autoComplete="off"
                  />
                  <ScrollWrapper> 
                    <SCCountryCodeList>
                      {filteredCountryList.map((country, index) => (
                        <SCCountryCodeItem
                          key={index}
                          onClick={() => handleCountrySelect(country)}
                        >
                          <img
                            src={`https://flagcdn.com/${(country?.iso).toLowerCase()}.svg`}
                            // alt={`${country?.name?.common} Flag`}
                          />
                          <span>
                            {country?.name}{" "}
                                  {country?.phonecode.startsWith("+")
                                  ? `(${country?.phonecode})`
                                  : `(+${country?.phonecode})`}
                          </span>
                        </SCCountryCodeItem>
                      ))}
                    </SCCountryCodeList> 
                  </ScrollWrapper>
                </Modal>
                <InputField>
                  <label htmlFor="phone">Mobile *</label>
                  <PhoneWrapper>
                    
                      <CountryCodeSelect onClick={() => {openModelHandeler();}}>
                        <img src={country?.flag} alt={`${country?.name}`} />
                        <span>{`${country?.countryCode}`}</span>
                      </CountryCodeSelect>
                      {/* <Select
                        name="country_code"
                        onChange={handleChange}
                        onBlur={handlePhoneBlur}
                      >
                        <option value="">Country Code</option>
                        {countryName?.map((item) => (
                          <option
                            key={item.phonecode}
                            value={item.phonecode}
                            selected={item.phonecode === values?.country_code}
                            style={{ backgroundImage: item?.iso}}
                          >
                            {getFlagEmoji(item?.iso)}
                            {" "}
                            {item?.phonecode.startsWith("+")
                              ? item?.phonecode
                              : `+${item?.phonecode}`}
                          </option>
                        ))}
                      </Select> */}
                    

                    {/* <div>
                      <TextField
                        name="area"
                        type="text"
                        placeholder="Area"
                        value={values?.area}
                        maxlength="30"
                        onChange={handleChange}
                        autoComplete="off"
                      />
                    </div> */}

                    <div>
                      <TextField
                        name="phone"
                        type="number"
                        onKeyDown={(e) =>
                          exceptThisSymbols.includes(e.key) &&
                          e.preventDefault()
                        }
                        value={values?.phone}
                        placeholder="Phone"
                        onChange={handleChange}
                        onBlur={handlePhoneBlur}
                        autoComplete="off"
                      />
                    </div>
                  </PhoneWrapper>
                  {errors?.country_code && (
                    <ErrorMsg>{errors?.country_code}</ErrorMsg>
                  )}
                  {/* {errors?.area && <ErrorMsg>{errors?.area}</ErrorMsg>} */}
                  {errors?.phone && <ErrorMsg>{errors?.phone}</ErrorMsg>}
                </InputField>

                <Row>
                  <InputField>
                    <label htmlFor="country">Country / Region*</label>
                    <CountrySelect
                      name="country"
                      onChange={(e) => {
                        handleChange(e);
                        getStates(e.target.value);
                      }}
                    >
                      <option value="">Select Country</option>
                      {countryName?.map((item) => (
                        <option
                          key={item.id}
                          value={item.id}
                          selected={item?.id == values?.country}
                        >
                          {item.name}
                        </option>
                      ))}
                    </CountrySelect>
                    {errors?.country && <ErrorMsg>{errors?.country}</ErrorMsg>}
                  </InputField>

                  <InputField>
                    <label htmlFor="state">State / Province*</label>
                    <CountrySelect name="state" onChange={handleChange}>
                      <option value="">Select State</option>
                      {states?.map((item) => (
                        <option
                          key={item.id}
                          value={item.id}
                          selected={item?.id == values?.state}
                        >
                          {item.name}
                        </option>
                      ))}
                    </CountrySelect>
                    {errors?.state && <ErrorMsg>{errors?.state}</ErrorMsg>}
                  </InputField>
                </Row>

                <Row>
                  <InputField>
                    <label htmlFor="password">Create password *</label>
                    <PasswordField>
                      <TextField
                        name="password"
                        type={passworType ? "password" : "text"}
                        placeholder={
                          languageData?.sign_up_page_input_password[
                            webSettingData?.language
                          ]
                        }
                        value={values?.password}
                        onChange={handleChange}
                      />
                      <svg
                        onClick={handlePasswordTypeChange}
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                        />
                        {passworType && (
                          <path
                            d="M2 2L22 22"
                            stroke="#000000"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        )}
                      </svg>
                    </PasswordField>
                    {errors?.password && (
                      <ErrorMsg>{errors?.password}</ErrorMsg>
                    )}
                  </InputField>

                  <InputField>
                    <label htmlFor="confirm_password">Confirm password *</label>
                    <PasswordField>
                      <TextField
                        name="confirm_password"
                        type={rePassworType ? "password" : "text"}
                        placeholder={
                          languageData?.sign_up_page_input_re_password[
                            webSettingData?.language
                          ]
                        }
                        value={values?.confirm_password}
                        onChange={handleChange}
                      />
                      <svg
                        onClick={handleRePasswordTypeChange}
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                        />
                        {rePassworType && (
                          <path
                            d="M2 2L22 22"
                            stroke="#000000"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        )}
                      </svg>
                    </PasswordField>
                    {errors?.confirm_password && (
                      <ErrorMsg>{errors?.confirm_password}</ErrorMsg>
                    )}
                  </InputField>
                </Row>

                <Extra>
                  <CheckBox>
                    <Input
                      type="checkbox"
                      name="terms_condition"
                      id="terms_condition"
                      isChecked={values?.terms_condition}
                      onChange={(e) => {
                        setValues((prevObj) => ({
                          ...prevObj,
                          terms_condition: e.target.checked,
                        }));
                        checkboxHandler(values);
                      }}
                    />
                    <Account htmlFor="terms_condition">
                      {
                        languageData?.sign_up_page_checkbox_text[
                          webSettingData?.language
                        ]
                      }
                      <ConditionLink
                        target="_blank"
                        to="/terms-and-condition"
                        textcolor={webSettingData?.buttoncolor}
                      >
                        {
                          languageData?.sign_up_page_terms_link_text[
                            webSettingData?.language
                          ]
                        }
                      </ConditionLink>
                    </Account>
                  </CheckBox>
                  {errors?.terms_condition && (
                    <ErrorMsg>{errors?.terms_condition}</ErrorMsg>
                  )}
                </Extra>

                <InputField>
                  <SignupButton
                    btnbgcolor={webSettingData?.buttoncolor}
                    btnbghvcolor={webSettingData?.buttoncolorhover}
                    btntextcolor={webSettingData?.buttontextcolor}
                  >
                    {
                      languageData?.sign_up_page_submit_button[
                        webSettingData?.language
                      ]
                    }
                  </SignupButton>
                </InputField>
                <div>
                  {
                    languageData?.sign_up_page_qustion_text[
                      webSettingData?.language
                    ]
                  }
                  <SingupLink
                    to="/login"
                    textcolor={webSettingData?.buttoncolor}
                  >
                    {
                      languageData?.sign_up_page_sign_in_link_text[
                        webSettingData?.language
                      ]
                    }
                  </SingupLink>
                </div>
              </Form>
            </InnerFormWrapper>
          </FormWrapper>
        )}
      </Container>
    </Layout>
  );
};
