const initialState = {
    userProfileDetails: null,
  };
  
  const userProfileDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
      case "SET_USER_PROFILE_DETAILS": {
        return {
          ...state,
          userProfileDetails: action.payload,
        };
      }
      default: {
        return state;
      }
    }
  };
  
  export default userProfileDetailsReducer;
  