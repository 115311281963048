import React, { useEffect, useState, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "./bootstrap/Spinner";
import PrivateRoute from "./components/Ui/PrivateRoute";
import languageInfo from "./lib/lang.config.json";
import { ForgotPassword, Home, Login, Signup } from "./pages";
import {
  appDataAction,
  contactInfo,
  language,
  webSetting,
} from "./redux/action/busAction";
import StripeForm from "./pages/Stripe/StripeForm";
import DbsForm from "./pages/Stripe/DbsForm";

const AllWork = React.lazy(() => import("./pages/AllWork"));
const AllTours = React.lazy(() => import("./pages/Tours"));
const TourGroups = React.lazy(() => import("./pages/TourGroups"));
const AllAttractions = React.lazy(() => import("./pages/Attractions"));
const AttractionGroups = React.lazy(() => import("./pages/AttractionGroups"));
const SingleWork = React.lazy(() => import("./pages/SingleWork"));
const Booking = React.lazy(() => import("./pages/Booking"));
const TourBooking = React.lazy(() => import("./pages/TourBooking"));
const AttractionBooking = React.lazy(() => import("./pages/AttractionBooking"));
const AllReview = React.lazy(() => import("./pages/AllReview"));
const Checkout = React.lazy(() => import("./pages/Checkout"));
const ContactUs = React.lazy(() => import("./pages/ContactUs"));
const Cookies = React.lazy(() => import("./pages/Cookies"));
const Faq = React.lazy(() => import("./pages/Faq"));
const Privacy = React.lazy(() => import("./pages/Privacy"));
const Terms = React.lazy(() => import("./pages/Terms"));
const Tickets = React.lazy(() => import("./pages/Tickets"));
const TicketTraking = React.lazy(() => import("./pages/TicketTraking"));
const About = React.lazy(() => import("./pages/About"));
const ResetPassword = React.lazy(() => import("./pages/ResetPassword"));
const OTPVerification = React.lazy(() => import("./pages/OTPVerification"));
const NotFound = React.lazy(() => import("./pages/NotFound"));
const Payments = React.lazy(() => import("./pages/Stripe/Payments"));
const CrossBorder = React.lazy(() => import("./pages/CrossBorder"));

function App() {
  const { webSettingData } = useSelector((state) => state.busLists);
  const [appData, setAppData] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();

  const getContactInfo = async () => {
    const ac = new AbortController();

    const response = await fetch(
      `${process.env.REACT_APP_API_MODULE_DOMAIN}/website/seetings/footer/content`,
      { signal: ac.signal }
    );
    const result = await response.json();

    if (result.status === "success") {
      dispatch(contactInfo(result?.data[0]));
    }

    return () => ac.abort();
  };

  useEffect(() => {
    try {
      getContactInfo();
    } catch (error) {
      console.log("Footer address error", error);
    }

    fetch(`${process.env.REACT_APP_API_DOMAIN}/app`)
      .then((res) => res.json())
      .then((result) => {
        if (result.status === "success") {
          setAppData(result?.data[0]);
          dispatch(appDataAction(result?.data[0]));
        }
      });

    fetch(`${process.env.REACT_APP_API_MODULE_DOMAIN}/website/seetings`)
      .then((res) => res.json())
      .then((result) => {
        if (result.status === "success") {
          dispatch(webSetting(result?.data));
        }
      });

    dispatch(language(languageInfo));
  }, []);

  useEffect(() => {
    document.querySelector(
      'link[rel="stylesheet"]'
    ).href = `https://fonts.googleapis.com/css?family=${webSettingData?.fontfamely}`;

    document.querySelector("body").style.fontFamily =
      webSettingData?.fontfamely;

    document.querySelector('link[rel="icon"]').href = webSettingData?.favicon;
  }, [webSettingData]);

  useEffect(() => {
    setTimeout(() => {
      localStorage.removeItem("token");
      localStorage.removeItem("userProfileInfo");
      history.replace("/");
      window.location.reload();
    }, 50 * 60 * 1000);
  }, []);

  return (
    <>
      <ToastContainer />
      {webSettingData ? (
        <Suspense fallback={<Spinner />}>
          <Switch>
            <Route
              exact
              path="/"
              component={() => <Home appData={appData} />}
            />
            {/* <Route exact path="/blog" component={AllBlog} /> */}
            <Route exact path="/work" component={AllWork} />
            <Route exact path="/tours" component={AllTours} />
            <Route exact path="/tours/:countryId" component={TourGroups} />
            <PrivateRoute path="/booking/tour">
              <TourBooking></TourBooking>
            </PrivateRoute>
            <Route exact path="/attractions" component={AllAttractions} />
            <Route
              exact
              path="/attractions/:countryId"
              component={AttractionGroups}
            />
            <PrivateRoute path="/booking/attraction">
              <AttractionBooking></AttractionBooking>
            </PrivateRoute>
            {/* <Route exact path="/blog/details/:id" component={SingleBlog} /> */}
            <Route exact path="/work/:id" component={SingleWork} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/forgotpassword" component={ForgotPassword} />
            <Route exact path="/signup" component={Signup} />
            <Route exact path="/booking" component={Booking} />
            <PrivateRoute path="/checkout">
              <Checkout></Checkout>
            </PrivateRoute>
            <PrivateRoute path="/tickets">
              <Tickets></Tickets>
            </PrivateRoute>
            <Route exact path="/ticket-traking" component={TicketTraking} />
            <Route exact path="/about" component={About} />
            {/* <Route exact path="/cookies" component={Cookies} /> */}
            <Route exact path="/privacy" component={Privacy} />
            <Route exact path="/terms-and-condition" component={Terms} />
            {/* <Route exact path="/faq" component={Faq} /> */}
            <Route exact path="/contact-us" component={ContactUs} />
            <Route exact path="/all-review" component={AllReview} />
            <Route exact path="/otp-verification" component={OTPVerification} />
            <Route exact path="/reset-password" component={ResetPassword} />
            <Route exact path="/payments" component={Payments} />
            <Route exact path="/crossborder" component={CrossBorder} />

            <Route path="*" component={NotFound} />
          </Switch>
        </Suspense>
      ) : (
        <Spinner />
      )}
    </>
  );
}

export default App;
