import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { StyledModalOverlay, StyledModalContainer } from "./Modal.styles";

const Modal = ({ children, isOpen, onClose }) => {

  return (
    <>
      {isOpen && <StyledModalOverlay onClick={() => onClose()} />}
      {isOpen &&
        <StyledModalContainer>
          {children}
        </StyledModalContainer>
      }
    </>
  )
};

ReactDOM.createPortal(Modal, document.getElementById("modal-root"));

export default Modal;