import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import TextField from "../../../bootstrap/TextField";
import languageData from "../../../lib/lang.config.json";
import {
  Apply,
  ApplyButton,
  ApplyHeader,
  ApplySubHeader,
  ApplyWrapper,
  Container,
} from "./PromoCode.styles";

const PromoCode = ({ setDiscountValue, subTotal }) => {
  const { webSettingData, attractionData,fareSummery } = useSelector(
    (state) => state.busLists
  );
  const [promoCode, setPromoCode] = useState("");
  const [journeyDate, setJourneyDate] = useState(null);
  const [journeyInfo, setJourneyInfo] = useState(null);
  const [bookingInfo, setBookingInfo] = useState(null);
  const [userProfileInfo, setUserProfileInfo] = useState(null);
  

  useEffect(() => {
    setJourneyDate(JSON.parse(localStorage.getItem("searchInfo")));
    setJourneyInfo(JSON.parse(localStorage.getItem("journeyInfo")));
    setBookingInfo(JSON.parse(localStorage.getItem("bookingInfo")));
    setUserProfileInfo(
      JSON.parse(localStorage.getItem('userProfileInfo'))
    );
    // console.log(attractionData)
  }, []);
  

  const handleChange = (e) => {
    setPromoCode(e.target.value);
  };

    const handleSubmit = async (e) => {
        e.preventDefault();
  
    if(!promoCode) {
      toast.error('Please Enter Promocode.');
      localStorage.removeItem("discount");
      return;
    }

    const coponCode = promoCode.trim();

    if (journeyInfo?.subtripId) {
      const response = await fetch(
        `${
          process.env.REACT_APP_API_MODULE_DOMAIN
        }/coupons/validation/${userProfileInfo?.user_id}/${coponCode.trim()}/${
          journeyDate?.returnDate
        }`
      );
      const result = await response.json();
      if (result?.status === "success") {
        if(subTotal > result?.discount)
          {
            !toast.isActive("validCoupon") &&
            toast.success("Coupon applied successfully.", {
              toastId: "validCoupon",
            });
            localStorage.setItem("discount", JSON.stringify({...result,couponCode:coponCode.trim(),relativeId:journeyInfo?.subtripId}));
            setDiscountValue(result);
          }
          else {
            toast.error('Total Amount is less then discount.');
            localStorage.removeItem("discount");
          }
        localStorage.setItem(
          "subtripId",
          JSON.stringify(journeyInfo?.subtripId)
        );
      } else {
        const response = await fetch(
          `${
            process.env.REACT_APP_API_MODULE_DOMAIN
          }/coupons/validation/${userProfileInfo?.user_id}/${coponCode.trim()}/${
            journeyDate?.journeydate
          }`
        );
        const result = await response.json();
        if (result?.status === "success") {
          
          if(subTotal > result?.discount)
          {
            !toast.isActive("validCoupon") &&
            toast.success("Coupon applied successfully.", {
              toastId: "validCoupon",
            });
            setDiscountValue(result);
            localStorage.setItem("discount", JSON.stringify({...result,couponCode:coponCode.trim(),relativeId:bookingInfo?.subtripId}));
          }
          else {
            toast.error('Total Amount is less then discount.');
            localStorage.removeItem("discount");
          }
        } else {
          !toast.isActive("invalidCoupon") &&
            toast.error(result.message, { toastId: "invalidCoupon" });
        }
        localStorage.setItem(
          "subtripId",
          JSON.stringify(bookingInfo?.subtripId)
        );
      }
    } 
   else if(bookingInfo?.subtripId) {
      const response = await fetch(
        `${
          process.env.REACT_APP_API_MODULE_DOMAIN
        }/coupons/validation/${userProfileInfo?.user_id}/${coponCode.trim()}/${
          journeyDate?.journeydate
        }`
      );
      const result = await response.json();
      if (result?.status === "success") {
        
          if(subTotal > result?.discount)
          {
            !toast.isActive("validCoupon") &&
            toast.success("Coupon applied successfully.", {
              toastId: "validCoupon",
            });
            localStorage.setItem("discount", JSON.stringify({...result,couponCode:coponCode.trim(),relativeId:bookingInfo?.subtripId}));
            setDiscountValue(result);

          }
          else {
            toast.error('Total Amount is less then discount.');
            localStorage.removeItem("discount");
          }
       
      } else {
        !toast.isActive("invalidCoupon") &&
          toast.error(result.message, { toastId: "invalidCoupon" });
      }
      localStorage.setItem(
        "subtripId",
        JSON.stringify(bookingInfo?.subtripId)
      );
    }
    
    
    else {
      const response = await fetch(
        `${
          process.env.REACT_APP_API_MODULE_DOMAIN
        }/coupons/validation/${userProfileInfo?.user_id}/${coponCode.trim()}/${attractionData?.attraction?.start_date}`
      );
      const result = await response.json();
      if (result?.status === "success") {
        if(subTotal > +result?.discount)
        {
          !toast.isActive("validCoupon") &&
          toast.success("Coupon applied successfully.", {
            toastId: "validCoupon",
          });
          setDiscountValue(result);
          localStorage.setItem("discount", JSON.stringify({...result,couponCode:coponCode.trim(),relativeId:attractionData?.attraction?.id}));
        }
        else {
          toast.error('Total Amount is less then discount.');
          localStorage.removeItem("discount");
        }  
      }
      if (result?.status === "fail") {
        !toast.isActive("invalidCoupon") &&
          toast.error(result?.message, { toastId: "invalidCoupon" });
      }

      localStorage.setItem("subtripId", JSON.stringify(bookingInfo?.subtripId));
    }
  };

  return (
    <Container>
      <Apply>
        <ApplyHeader>
          {languageData?.side_bar_dicount_title[webSettingData?.language]}
        </ApplyHeader>
        <ApplySubHeader>
          {languageData?.side_bar_dicount_sub_title[webSettingData?.language]}
        </ApplySubHeader>
        {/* <ApplySubHeader>Redeem</ApplySubHeader> */}
        <form onSubmit={handleSubmit}>
          <ApplyWrapper>
            <TextField
              id="promo_code"
              value={promoCode}
              type="text"
              onChange={handleChange}
              placeholder={
                languageData?.side_bar_promo_code_text[webSettingData?.language]
              }
            />
            <ApplyButton
              type="submit"
              btnbgcolor={webSettingData?.buttoncolor}
              btnbghvcolor={webSettingData?.buttoncolorhover}
              btntextcolor={webSettingData?.buttontextcolor}
            >
              {languageData?.side_bar_promo_code_btn[webSettingData?.language]}
            </ApplyButton>
          </ApplyWrapper>
        </form>
      </Apply>
    </Container>
  );
};

export default PromoCode;
