import React, { useEffect, useRef, useState } from "react";
import Layout from "../../bootstrap/Layout";
import Blog from "../../components/Ui/Blog";
import Hero from "../../components/Ui/Hero";
import Journy from "../../components/Ui/Journy";
import Tours from "../../components/Ui/Tours";
import Attraction from "../../components/Ui/Attractions";
import MobileApp from "../../components/Ui/MobileApp";
import SearchForm from "../../components/Ui/SearchFrom";
import Subscribe from "../../components/Ui/Subscribe";
import Testimonial from "../../components/Ui/Testimonial";
import Work from "../../components/Ui/Work";
import {
  HeroArea,
  SearchFromArea,
  CrossBoarderImg,
  CrossImage,
  TourWrapper,
} from "./Home.styles.js";
import CrossBoarderImage from "../../assets/images/cross-boarder.jpg";
import Container from "../../bootstrap/Container";
import instance from "../../axiosInstance";

export const Home = ({ appData }) => {
  const locationRef = useRef(null);
  const [userProfileInfo, setUserProfileInfo] = useState(null);
  const [token, setToken] = useState(null);
  useEffect(() => {
    const userInfo = localStorage.getItem("userProfileInfo");
    const userToken = localStorage.getItem("token");
    setToken(userToken);
    if (userInfo) {
      setUserProfileInfo(JSON.parse(userInfo));
    }
  }, []);
  useEffect(() => {
    if (token) {
      setTimeout(() => {
        instance
          .get(`${process.env.REACT_APP_API_MODULE_DOMAIN}/passangers/info`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            const result = response.data;
            if (result?.status === "success") {
              const data = result?.data;
              setUserProfileInfo(data);
              localStorage.setItem("userProfileInfo", JSON.stringify(data));
            }
          })
          .catch((error) => {
            console.error("Error fetching passenger information:", error);
          });
      }, 1000);
    }
  }, [token]);
  return (
    <Layout title="Home" userProfileInfo={userProfileInfo}>
      <HeroArea>
        <Hero locationRef={locationRef} />
        <SearchFromArea>
          <SearchForm locationRef={locationRef} />
        </SearchFromArea>
      </HeroArea>

      <Work />
      <Journy />
      <TourWrapper>
        <Tours
          heading="tour segment"
          subHeading="tours"
          showLoadMoreButton={false}
        />
      </TourWrapper>
      <Attraction subHeading="attraction" showLoadMoreButton={false} />
      {/* <CrossImage> */}
      <CrossBoarderImg to='/crossborder'>
        <img src={CrossBoarderImage} alt="transtar cross boarder" />
      </CrossBoarderImg>
      {/* </CrossImage> */}
      {/* <Testimonial />
      <MobileApp />
      <Blog />
      <Subscribe /> */}
    </Layout>
  );
};
