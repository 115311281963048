import moment from "moment";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
// import Select from "react-select";
import { toast } from "react-toastify";
import exchangePhoto from "../../../assets/images/exchange.svg";
import { dateForm, getDropLocation, getPicLocation, getQueryParamValue } from "../../../helpers.js";
import {
  addBusName,
  addError,
  clearSavedBookIds,
  isLoading,
  regularBookingInformation,
  SearchInfoStore,
  updateFareSummery,
} from "../../../redux/action/busAction.js";
import {
  BookingPageDate,
  BookingPageExchangePhoto,
  BookingPageInnerForm,
  BookingPageJourneyDate,
  BookingPageLocation,
  BookingPageReturnDate,
  BookingPageSearchBtn,
  Calendar,
  DateInput,
  DateText,
  ExchangePhoto,
  Form,
  InnerForm,
  JourneyDate,
  Location,
  ReturnDate,
  SearchBtn,
  SearchBtnArea,
  SearchLocation,
  BookingPageExchangePhotoTwo,
  SearchBtnAreaFindTrip,
} from "./SearchFrom.styles.js";

const SearchForm = ({ locationRef, searchInfo }) => {
  const currentURL = useLocation();
  // const queryParams = new URLSearchParams(currentURL.search);
  const { webSettingData, languageData, isLoadings } = useSelector(
    (state) => state.busLists
  );
  const [location, setLocation] = useState([]);

  const history = useHistory();
  const isHome = history.location.pathname !== "/";
  const dispatch = useDispatch();

  useEffect(() => {
    if (searchInfo?.journeydate) {
      setJournyStartDate(
        new window.Date(searchInfo?.journeydate.replace(/-/g, "/"))
      );
    }

    // if (searchInfo?.returnDate && searchInfo.returnDate != "NaN-NaN-NaN") {
    //   setJournyReturnDate(
    //     new window.Date(searchInfo?.returnDate.replace(/-/g, "/"))
    //   );
    // }
  }, [searchInfo]);

  const cursorStyles = {
    option: (styles) => {
      return {
        ...styles,
        cursor: "pointer",
      };
    },
  };

  let dropLocation = searchInfo?.dropLocation || "";
  let pickLocation = searchInfo?.pickLocation || "";
  let journeyDate = new window.Date();
  let returnJourneyDate = "";

  if (window.location.search.length > 0) {
    dropLocation = getQueryParamValue("drop");
    pickLocation = getQueryParamValue("pickup");

    const date =  getQueryParamValue("date");
    journeyDate = date ? parseDate(date) : new window.Date();

    const returnDate =  getQueryParamValue("return-date");
    returnJourneyDate = returnDate ? parseDate(returnDate) : "";

    function parseDate(dateString) {
      const [year, month, day] = dateString.split('-').map(Number);
    
      // Ensure the month has two digits
      const formattedMonth = month < 10 ? `0${month}` : month;
      const formattedDay = day < 10 ? `0${day}` : day;
    
      return new Date(`${year}-${formattedMonth}-${formattedDay}`);
    }
  }

  const [values, setValues] = useState({
    drop_location: dropLocation,
    pick_location: pickLocation,
  });
  const [journyStartDate, setJournyStartDate] = useState(journeyDate);
  const [journyReturnDate, setJournyReturnDate] = useState(returnJourneyDate);

  useEffect(() => {
    setValues({
      ...values,
      drop_location: dropLocation,
      pick_location: pickLocation,
    });
  }, [searchInfo, currentURL]);

  const handleBoardingChange = (selectOption) => {
    setValues({ ...values, pick_location: selectOption.id });
    if (selectOption.id === values?.drop_location) {
      !toast.isActive("sameLocation") &&
        toast.error("Boarding point and destination must not be the same", {
          toastId: "sameLocation",
        });
    }
  };

  const handleDroppingChange = (selectOption) => {
    setValues({ ...values, drop_location: selectOption.id });
    if (selectOption.id === values?.pick_location) {
      !toast.isActive("sameLocation") &&
        toast.error("Boarding point and destination must not be the same", {
          toastId: "sameLocation",
        });
    }
  };

  let locations = [];
  location?.map((item) =>
    locations.push({
      value: item?.name,
      label: item?.name,
      id: item?.id,
    })
  );

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Remove all old localstoreage data
    dispatch(regularBookingInformation(" "));
    dispatch(updateFareSummery(null));
    // clear the bookingIds array from redux
    dispatch(clearSavedBookIds());
    localStorage.removeItem("bookingInfo");
    localStorage.removeItem("searchInfo");
    localStorage.removeItem("returnFirstJourneyInfo");
    localStorage.removeItem("journeyInfo");

    localStorage.removeItem("discount");
    localStorage.removeItem("regular");
    localStorage.removeItem("return");
    localStorage.removeItem("bookingInfoTax");
    localStorage.removeItem("journeyInfoTax");
    localStorage.removeItem("subtripId");
    localStorage.removeItem("passengerInformation");
    dispatch(addError(""));

    // localStorage.clear();

    const formData = new FormData();
    const pickLocation = location.find(
      (item) => item.id === values.pick_location
    );
    const dropLocation = location.find(
      (item) => item.id === values.drop_location
    );
    const toastId = "searchTrip";
    if (!pickLocation?.id && !dropLocation?.id) {
      !toast.isActive(toastId) &&
        toast.error("Please select your pick up and drop location.", {
          toastId: toastId,
        });
      return;
    } else if (pickLocation?.id === dropLocation?.id) {
      !toast.isActive("sameLocation") &&
        toast.error("Boarding point and destination must not be the same", {
          toastId: "sameLocation",
        });
      return;
    } else if (!pickLocation?.id) {
      !toast.isActive("pickup") &&
        toast.error("Please select your pick up point", { toastId: "pickup" });
      return;
    } else if (!dropLocation?.id) {
      !toast.isActive("drop") &&
        toast.error("Please select your destination", { toastId: "drop" });
      return;
    } else if (!journyStartDate) {
      !toast.isActive("journyDate") &&
        toast.error("Please select your journey date", {
          toastId: "journyDate",
        });
      return;
    }

    formData.append("pick_location_id", pickLocation?.id);
    formData.append("drop_location_id", dropLocation?.id);
    formData.append("journeydate", dateForm(journyStartDate));

    const searchInfo = {
      pickLocation: pickLocation?.id,
      dropLocation: dropLocation?.id,
      journeydate: dateForm(journyStartDate),
      returnDate: dateForm(journyReturnDate),
    };
    dispatch(SearchInfoStore(searchInfo));
    localStorage.setItem("searchInfo", JSON.stringify(searchInfo));

    function parseDateString(dateString) {
      const [year, month, day] = dateString.split("-").map(Number);
      return new Date(year, month - 1, day);
    }

    const journeydate = parseDateString(searchInfo?.journeydate);
    const returnDate = parseDateString(searchInfo?.returnDate);

    if (journeydate > returnDate) {
      !toast.isActive("invalidReturnDate") &&
        toast.error("Invalid journey date & return date.", {
          toastId: "invalidReturnDate",
        });
      return;
    }
    if (searchInfo?.journeydate === searchInfo?.returnDate) {
      !toast.isActive("sameDate") &&
        toast.error("Journey date & return date can't be same.", {
          toastId: "sameDate",
        });
      return;
    }
    dispatch(isLoading(true));
    const response = await fetch(
      `${process.env.REACT_APP_API_MODULE_DOMAIN}/triplist`,
      {
        method: "POST",
        body: formData,
      }
    );
    const result = await response.json();

    if (result.status === "success") {
      dispatch(addBusName(result.data));
      dispatch(isLoading(false));
      dispatch(addError(""));
      history.push("/booking");
    } else {
      dispatch(addBusName(null));
      dispatch(isLoading(false));
      dispatch(addError(result?.message));
      toast.error(`${result?.message}`);
    }
  };

  const locationData = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_MODULE_DOMAIN}/locations`
    );
    const result = await response.json();
    setLocation(result.data);
  };

  useEffect(() => {
    try {
      locationData();
      return () => {
        setLocation({});
      };
    } catch (error) {
      console.log("searchForm error", error);
    }
  }, []);
  const handleSwap = () => {
    const pickup = values.drop_location;
    const drop = values.pick_location;
    setValues({ ...values, drop_location: drop, pick_location: pickup });
  };
  return (
    <>
      <Form onSubmit={handleSubmit}>
        {!isHome ? (
          // for home page
          <>
            <ExchangePhoto
              headercolor={webSettingData?.buttoncolor}
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              style={{ cursor: "pointer" }}
              onClick={handleSwap}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4"
              />
            </ExchangePhoto>
            <InnerForm>
              <Location>
                <SearchLocation
                  styles={cursorStyles}
                  placeholder={
                    languageData?.search_form_from_input[
                      webSettingData?.language
                    ]
                  }
                  options={locations}
                  value={locations[getPicLocation(locations, values)]}
                  onChange={handleBoardingChange}
                  ref={locationRef}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                />
                <SearchLocation
                  styles={cursorStyles}
                  placeholder={
                    languageData?.search_form_to_input[webSettingData?.language]
                  }
                  options={locations}
                  value={locations[getDropLocation(locations, values)]}
                  onChange={handleDroppingChange}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                />
              </Location>

              <DateInput>
                <JourneyDate>
                  <DateText>
                    {
                      languageData?.search_form_start_date[
                        webSettingData?.language
                      ]
                    }
                  </DateText>
                  <Calendar
                    onChange={setJournyStartDate}
                    value={journyStartDate}
                    clearIcon={null}
                    dateFormat="YYYY-MM-DD"
                    minDate={moment().toDate()}
                  />
                </JourneyDate>
                <ReturnDate>
                  <DateText>
                    {
                      languageData?.search_form_retrurn_date[
                        webSettingData?.language
                      ]
                    }
                  </DateText>
                  <Calendar
                    onChange={setJournyReturnDate}
                    valueDefault={null}
                    value={journyReturnDate}
                    clearIcon={null}
                    dateFormat="YYYY-MM-DD"
                    minDate={moment().toDate()}
                    dayPlaceholder="dd"
                    monthPlaceholder="mm"
                    yearPlaceholder="yyyy"
                  />
                </ReturnDate>
              </DateInput>
            </InnerForm>
            <SearchBtnArea>
              <SearchBtn
                searchbtnbgcolor={webSettingData?.buttoncolor}
                searchbtnbghvcolor={webSettingData?.buttoncolorhover}
                btntextcolor={webSettingData?.buttontextcolor}
                type="submit"
              >
                {languageData?.search_form_button[webSettingData?.language]}
              </SearchBtn>
            </SearchBtnArea>
          </>
        ) : (
          <div>
            {/* for booking page */}

            <BookingPageExchangePhotoTwo>
              {/* <ExchangePhoto
                headercolor={webSettingData?.buttoncolor}
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4"
                />
              </ExchangePhoto> */}
            </BookingPageExchangePhotoTwo>
            <BookingPageInnerForm>
              <BookingPageLocation>
                <SearchLocation
                  styles={cursorStyles}
                  placeholder={
                    languageData?.search_form_from_input[
                      webSettingData?.language
                    ]
                  }
                  options={locations}
                  value={locations[getPicLocation(locations, values)]}
                  onChange={handleBoardingChange}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                />
                <SearchLocation
                  styles={cursorStyles}
                  placeholder={
                    languageData?.search_form_to_input[webSettingData?.language]
                  }
                  options={locations}
                  value={locations[getDropLocation(locations, values)]}
                  onChange={handleDroppingChange}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                />
              </BookingPageLocation>

              <BookingPageDate>
                <BookingPageJourneyDate>
                  <DateText>Depart</DateText>
                  <Calendar
                    onChange={setJournyStartDate}
                    value={journyStartDate}
                    clearIcon={null}
                    dateFormat="YYYY-MM-DD"
                    minDate={moment().toDate()}
                  />
                </BookingPageJourneyDate>
                <BookingPageReturnDate>
                  <DateText>Return (Optional)</DateText>
                  <Calendar
                    onChange={setJournyReturnDate}
                    value={journyReturnDate}
                    clearIcon={null}
                    dateFormat="YYYY-MM-DD"
                    minDate={moment().toDate()}
                    valueDefault={null}
                    dayPlaceholder="dd"
                    monthPlaceholder="mm"
                    yearPlaceholder="yyyy"
                  />
                </BookingPageReturnDate>
              </BookingPageDate>
              <SearchBtnAreaFindTrip>
                <BookingPageSearchBtn
                  searchbtnbgcolor={webSettingData?.buttoncolor}
                  searchbtnbghvcolor={webSettingData?.buttoncolorhover}
                  btntextcolor={webSettingData?.buttontextcolor}
                >
                  {
                    languageData?.search_form_button_booking_page[
                      webSettingData?.language
                    ]
                  }
                </BookingPageSearchBtn>
              </SearchBtnAreaFindTrip>
            </BookingPageInnerForm>
          </div>
        )}
      </Form>
    </>
  );
};

export default SearchForm;
