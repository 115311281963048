import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import busLists from "./reducers/busReducer";
import { persistenceMiddleware } from "./persistenceMiddleware";
import tourBooking from "./reducers/tourBookingReducer";
import tourGroupReducer from "./reducers/tourGroupsReducer";
import attractionGroupReducer from "./reducers/attractionGroupsReducer";
import userSignUpDetailsReducer from "./reducers/userSignUpReducer";
import userProfileDetailsReducer from "./reducers/userProfileReducer";

const middleware = applyMiddleware(thunk, persistenceMiddleware);
const persistedState = localStorage.getItem("reduxState");
const initialState = persistedState ? JSON.parse(persistedState) : {};
const combinedReducer = combineReducers({
  busLists,
  tourBooking,
  tourGroupReducer,
  attractionGroupReducer,
  userSignUpDetailsReducer,
  userProfileDetailsReducer,
});

export const store = createStore(
  combinedReducer,
  initialState,
  composeWithDevTools(middleware)
);
